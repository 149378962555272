import React, { useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UpdateIcon from "@material-ui/icons/EditTwoTone";
import AddNoteIcon from "@material-ui/icons/PostAddTwoTone";
import AddDocumentIcon from "@material-ui/icons/NoteAddTwoTone";
import AddReferenceIcon from "@material-ui/icons/InsertLinkTwoTone";
import WithdrawIcon from "@material-ui/icons/RemoveCircleTwoTone";
import SubmitIcon from "@material-ui/icons/SendTwoTone";
import AcceptIcon from "@material-ui/icons/DoneTwoTone";
import RequestInfoIcon from "@material-ui/icons/HelpTwoTone";
import ConfirmInfoProvidedIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import CompleteIcon from "@material-ui/icons/CheckCircleTwoTone";
import RejectIcon from "@material-ui/icons/ThumbDownAltTwoTone";
import ReopenIcon from "@material-ui/icons/UndoTwoTone";
import AssignIcon from "@material-ui/icons/AssignmentIndTwoTone";
import AddChildIcon from "@material-ui/icons/AddCircleTwoTone";
import auth from "../../../utils/auth";
import ReferralStates from "../../../services/ReferralStates";
import PropTypes from "prop-types";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export function ReferralActionsMenu({ referral, patientInfo, users, handlers }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canShow = (showPredicate) => {
    return (!referral || (referral && showPredicate(auth, referral, patientInfo)));
  }

  const ReferralActionMenuOption = ({ Icon, text, action, disabled }) => {
    return (
      <StyledMenuItem onClick={() => {
        action();
        handleClose();
      }}
      disabled={disabled}>
        <ListItemIcon>
          <Icon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary={text}/>
      </StyledMenuItem>
    );
  };

  const menuOptions = [
    referral && canShow(ReferralStates.canUpdate) ?
      <ReferralActionMenuOption
        Icon={UpdateIcon}
        text="Update"
        action={handlers.updateHandler}
      /> : null
    ,
    canShow(ReferralStates.canAddNote) ?
      <ReferralActionMenuOption
        Icon={AddNoteIcon}
        text="Add Note"
        action={handlers.addNoteHandler}
      /> : null
    ,
    canShow(ReferralStates.canAddDocument) ?
      <ReferralActionMenuOption
        Icon={AddDocumentIcon}
        text="Add Document"
        action={handlers.addDocumentHandler}
      /> : null
    ,
    canShow(ReferralStates.canAddDocument) ?
      <ReferralActionMenuOption
        Icon={AddReferenceIcon}
        text="Add Reference"
        action={handlers.addReferenceHandler}
      /> : null
    ,
    canShow(ReferralStates.canWithdraw) ?
      <ReferralActionMenuOption
        Icon={WithdrawIcon}
        text="Withdraw"
        action={handlers.withdrawHandler}
      /> : null
    ,
    canShow(ReferralStates.canSubmit) ?
      <ReferralActionMenuOption
        Icon={SubmitIcon}
        text="Submit"
        action={handlers.submitHandler}
      /> : null
    ,
    canShow(ReferralStates.canAccept) ?
      <ReferralActionMenuOption
        Icon={AcceptIcon}
        text="Accept"
        action={handlers.acceptHandler}
      /> : null
    ,
    canShow(ReferralStates.canRequestMissingInfo) ?
      <ReferralActionMenuOption
        Icon={RequestInfoIcon}
        text="Request more information"
        action={handlers.requestInfoHandler}
      /> : null
    ,
    canShow(ReferralStates.canNotifyInfoProvided) ?
      <ReferralActionMenuOption
        Icon={ConfirmInfoProvidedIcon}
        text="Confirm information provided"
        action={handlers.confirmInfoProvidedHandler}
      /> : null
    ,
    canShow(ReferralStates.canComplete) ?
      <ReferralActionMenuOption
        Icon={CompleteIcon}
        text="Complete"
        action={handlers.completeHandler}
      /> : null
    ,
    canShow(ReferralStates.canReject) ?
      <ReferralActionMenuOption
        Icon={RejectIcon}
        text="Reject"
        action={handlers.rejectHandler}
      /> : null
    ,
    canShow(ReferralStates.canReopen) ?
      <ReferralActionMenuOption
        Icon={ReopenIcon}
        text="Reopen"
        action={handlers.reopenHandler}
      /> : null
    ,
    canShow(ReferralStates.canAssign) ?
      <ReferralActionMenuOption
        Icon={AssignIcon}
        text="Assign"
        action={handlers.assignHandler}
        disabled={users.length === 0}
      /> : null
    ,
    canShow(ReferralStates.canCreateChild) ?
      <ReferralActionMenuOption
        Icon={AddChildIcon}
        text="Add Child"
        action={handlers.addChildHandler}
      /> : null
    ,
  ]
    .filter(Boolean)
  ;

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={menuOptions.length === 0}
      >
        Referral Actions
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuOptions}
      </StyledMenu>
    </div>
  );
}

ReferralActionsMenu.propTypes = {
  referral: PropTypes.object.isRequired,
  patientInfo: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    updateHandler: PropTypes.func.isRequired,
    addNoteHandler: PropTypes.func.isRequired,
    addDocumentHandler: PropTypes.func.isRequired,
    addReferenceHandler: PropTypes.func.isRequired,
    withdrawHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    acceptHandler: PropTypes.func.isRequired,
    requestInfoHandler: PropTypes.func.isRequired,
    confirmInfoProvidedHandler: PropTypes.func.isRequired,
    completeHandler: PropTypes.func.isRequired,
    rejectHandler: PropTypes.func.isRequired,
    reopenHandler: PropTypes.func.isRequired,
    assignHandler: PropTypes.func.isRequired,
    addChildHandler: PropTypes.func.isRequired,
  }).isRequired,
};