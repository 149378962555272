import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  useField,
  useForm,
  useOptionalField,
} from "../../../../../utils/form-utils";
import { FormList, FormText } from "../../controls";
import {
  achievementStatusProperty,
  categoryProperty,
  lifecycleStatusProperty,
} from "../../utils/fhir-utils/domain/Goal";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import colors from "../../../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(1),
    width: 260,
  },
  formTextArea: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(1),
  },
}));

const EMPTY_VALUES = {
  organization: "",
  patientName: "",
  category: "",
  status: "",
  achievementStatus: "",
  owner: "",
  startDate: null,
  dueDate: null,
  description: "",
  saving: false,
  saveError: "",
};

export function GoalEditDialog({ goal, open, onClose, onSubmit }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(EMPTY_VALUES.saving);
  const [saveError, setSaveError] = useState(EMPTY_VALUES.saveError);
  const form = useForm();

  const organization = useOptionalField(
    form,
    "Organization",
    goal?.getOrganizationName() || EMPTY_VALUES.organization
  );

  const patientName = useOptionalField(
    form,
    "Patient Name",
    goal?.getPatientName() || EMPTY_VALUES.patientName
  );

  const category = useField(
    form,
    "Category",
    goal?.getCategoryId() || EMPTY_VALUES.category
  );

  const lifecycleStatus = useField(
    form,
    "Status",
    goal?.getStatusId() || EMPTY_VALUES.status
  );

  const achievementStatus = useField(
    form,
    "Achievement Status",
    goal?.getAchievementStatusId() || EMPTY_VALUES.achievementStatus
  );

  const owner = useField(form, "Owner", goal?.getOwner() || EMPTY_VALUES.owner);

  const startDate = useField(
    form,
    "Start Date",
    goal?.getStartDate() || EMPTY_VALUES.startDate
  );

  const dueDate = useField(
    form,
    "Due Date",
    goal?.getDueDate() || EMPTY_VALUES.dueDate
  );

  const description = useField(
    form,
    "Description",
    goal?.getDescription() || EMPTY_VALUES.description
  );

  const reset = () => {
    form.reset();
    setSaving(EMPTY_VALUES.saving);
    setSaveError(EMPTY_VALUES.saveError);
  };

  const submit = async () => {
    if (form.isInvalid || !goal) {
      return;
    }
    try {
      setSaving(true);
      await goal.setCategory(category.value);
      goal.setStatus(lifecycleStatus.value);
      await goal.setAchievementStatus(achievementStatus.value);
      goal.setOwner(owner.value);
      goal.setStartDate(startDate.value);
      goal.setDueDate(dueDate.value);
      goal.setDescription(description.value);
      await onSubmit(goal);
      reset();
      onClose();
    } catch (error) {
      setSaving(EMPTY_VALUES.saving);
      setSaveError(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} onExited={reset} onEnter={reset}>
      <DialogTitle>{goal?.isNew() ? "Add a" : "Edit"} Goal</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormText field={organization} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormText field={patientName} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={category}
                getValues={categoryProperty?.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={lifecycleStatus}
                getValues={lifecycleStatusProperty?.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={achievementStatus}
                getValues={achievementStatusProperty?.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={owner}
                getValues={() =>
                  goal?.getCarePlan()?.getAllCareTeamMemberOptions()
                }
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText field={startDate} type="date" classes={classes} />
            </Grid>
            <Grid item xs={6}>
              <FormText field={dueDate} type="date" classes={classes} />
            </Grid>
            <Grid item xs={12}>
              <FormText
                field={description}
                multiline
                fullWidth
                variant="outlined"
                classes={classes}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          disabled={saving}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={form.isInvalid || saving}
        >
          {saveError ? "Retry" : "Save"}
        </Button>
      </DialogActions>
      {saving && (
        <ThemeProvider theme={theme}>
          <div>
            <CircularProgress />
          </div>
        </ThemeProvider>
      )}
      {saveError && (
        <MuiAlert severity="error" elevation={6} variant="filled">
          {saveError}
        </MuiAlert>
      )}
    </Dialog>
  );
}
