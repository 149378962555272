import _ from "lodash";
import {
  formatDateHumanMDY,
  formatDateHumanMDYFromNow,
} from "../../../date-utils";
import { ifNotEmpty } from "../../../string-utils";
import {
  descriptionProperty,
  dueDateProperty,
  lifecycleStatusProperty,
  ownerProperty,
  startDateProperty,
  categoryProperty,
  achievementStatusProperty
} from "./goal-properties";
import { getDisplayName } from "../domain-utils";

export class Goal {
  data;

  #isNew;
  #carePlan;

  constructor(rawGoal, carePlan) {
    this.data = rawGoal || {
      resourceType: "Goal",
      subject: carePlan.getSubjectReference(),
    };
    this.#isNew = !rawGoal;
    this.#carePlan = carePlan;
  }

  isNew() {
    return this.#isNew;
  }

  getCarePlan() {
    return this.#carePlan;
  }

  addToCarePlan(rawGoal) {
    this.#carePlan.addGoal(rawGoal);
  }

  getDueDate() {
    return dueDateProperty.getFrom(this.data, null);
  }

  getDueDateMDY = () => {
    return ifNotEmpty(this.getDueDate(), formatDateHumanMDY, "");
  };

  getDueDateMDYFromNow() {
    return ifNotEmpty(this.getDueDate(), formatDateHumanMDYFromNow, "");
  }

  setDueDate(dateStr) {
    return dueDateProperty.setTo(this.data, dateStr);
  }

  getPatient() {
    return this.#carePlan?.getSubject();
  }

  getPatientName() {
    return getDisplayName(this.getPatient());
  }

  getStartDate() {
    return startDateProperty.getFrom(this.data, null);
  }

  setStartDate(date) {
    startDateProperty.setTo(this.data, date);
  }

  getOrganizationName() {
    return this.getPatient()?.getManagingOrganization()?.name;
  }

  getDescription() {
    return descriptionProperty.getFrom(this.data, null);
  }

  setDescription(description) {
    descriptionProperty.setTo(this.data, description);
  }

  getStatus() {
    return lifecycleStatusProperty.getFrom(this.data);
  }

  getStatusId() {
    return this.data["lifecycleStatus"];
  }

  setStatus(status) {
    return lifecycleStatusProperty.setTo(this.data, status);
  }

  getAchievementStatusId() {
    return _.get(this.data, "achievementStatus.coding[0].code");
  }

  async setAchievementStatus(statusId) {
    const items = await achievementStatusProperty.getItems();
    const catalog = await achievementStatusProperty.getCatalog();
    const item = items.find((item) => item.code === statusId);
    if (item) {
      this.data.achievementStatus = {
        coding: [
          {
            code: item.code,
            system: catalog.url,
            display: item.display,
          },
        ],
        text: item.display,
      };
    }
  }

  getCategory() {
    const category = this.data.category?.[0];
    return _.get(category, "coding[0].display") || _.get(category, "text");
  }

  getCategoryId() {
    return _.get(this.data, "category[0].coding[0].code");
  }

  async setCategory(categoryId) {
    const include = (await categoryProperty.getCatalog())?.compose?.include?.[0];
    const item = (include?.concept || []).find(
      (concept) => concept.code === categoryId
    );
    if (item) {
      this.data.category = [
        {
          coding: [
            {
              code: item.code,
              system: include.system,
              display: item.display,
            },
          ],
          text: item.display,
        },
      ];
    }
  }

  getOwner() {
    return ownerProperty.getFrom(this.data, null) || "";
  }

  setOwner(ownerReferenceValue) {
    ownerProperty.setTo(this.data, ownerReferenceValue);
  }
}
