import React, { useContext, useEffect } from 'react';
import { observable } from 'mobx';
import { BrowserRouter, matchPath, Route, useLocation } from 'react-router-dom';
import auth from './utils/auth';
import PatientsHome from './components/PatientsHome';
import { Home } from './components/Home';
import Login from './components/Login/Login';
import ConfirmInvite from './components/ConfirmInvite/ConfirmInvite'
import PatientInfo from './components/PatientInfo';
import Viewer from './components/PatientViewer/Documents/DocumentFile';
import { lastUrlKey, useFhirReferrals } from './utils/constants';
import { AuthContext } from './AuthProvider';
import ReferralDetail from './components/PatientViewer/Referrals/ReferralDetail';
import ReferralManagement from './components/ReferralManagement';
import UserManagement from './components/UserManagement';
import {
  Home as HomeIcon,
  FindInPage,
  Loop,
  Description,
  ListAlt
} from '@material-ui/icons';
import FhirReferralDetail from './components/PatientViewer/FhirReferrals/FhirReferralDetail';

export const routes = observable([
  {
    path: '/',
    pathnameRegex: /^\/$/,
    component: Home,
    breadcrumbs: ['HOME'],
    icon: HomeIcon,
    onClick: (history, currentPathname, patientData) => {
      history.push('/');
    },
  },
  {
    path: '/patients',
    pathnameRegex: /^\/patients$/,
    component: PatientsHome,
    breadcrumbs: ['HOME', 'SEARCH'],
    icon: FindInPage,
    onClick: (history, currentPathname, patientData) => {
      history.push('/patients');
    },
  },
  {
    path: '/referrals',
    pathnameRegex: /^\/referrals$/,
    component: ReferralManagement,
    breadcrumbs: ['HOME', 'REFERRALS'],
    icon: Loop,
    onClick: (history, currentPathname, patientData) => {
      history.push('/referrals');
    },
  },
  {
    name: 'PATIENT_DOCUMENTS',
    path: '/documents/:patientId',
    pathnameRegex: /^\/documents\/[A-Za-z0-9-_.]+$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/documents/${patientData.id}`);
    },
  },
  {
    name: 'PATIENT_DOCUMENTS',
    path: '/documents/:patientId/:app',
    pathnameRegex: /^\/documents\/[A-Za-z0-9-_.]+\/[0-9]+$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/documents/${patientData.id}/1`);
    },
  },
  {
    name: 'PATIENT_DOCUMENTS',
    path: '/patient/:patientId/info',
    pathnameRegex: /^\/patient\/[A-Za-z0-9-_.]+\/info$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/${patientData.id}/info`);
    },
  },
  {
    name: 'PATIENT_DOCUMENTS',
    path: '/patient/:patientId/info/:app',
    pathnameRegex: /^\/patient\/[A-Za-z0-9-_.]+\/info\/[0-9]+$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/${patientData.id}/info/1`);
    },
  },
  {
    name: 'CURES',
    path: '/patient/search/:patientId/info/:app/cures',
    pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[A-Za-z0-9]+\/cures$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'CURES'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/search/${patientData.id}/info/1/cures`);
    },
  },
  {
    name: 'PATIENT_REFERRALS',
    path: '/patient/search/:patientId/info/:app/referrals',
    pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[A-Za-z0-9]+\/referrals$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'REFERRALS'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/search/${patientData.id}/info/1/referrals`);
    },
  },
  // {
  //   name: 'FHIR_PATIENT_REFERRALS',
  //   path: '/patient/search/:patientId/info/:app/fhir-referrals',
  //   pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[A-Za-z0-9]+\/fhir-referrals$/,
  //   component: PatientInfo,
  //   breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'REFERRALS'],
  //   icon: ListAlt,
  //   onClick: (history, currentPathname, patientData) => {
  //     history.push(`/patient/search/${patientData.id}/info/1/fhir-referrals`);
  //   },
  // },
  {
    name: 'PATIENT_PREVENTION_LINK',
    path: '/patient/search/:patientId/info/:app/prevention-link',
    pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[A-Za-z0-9]+\/prevention-link$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'PREVENTIONLINK'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/search/${patientData.id}/info/1/prevention-link`);
    },
  },
  {
    name: 'PATIENT_CAREPLANS',
    path: '/patient/search/:patientId/info/:app/care-plans',
    pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[A-Za-z0-9]+\/care-plans$/,
    component: PatientInfo,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'CASES'],
    icon: ListAlt,
    onClick: (history, currentPathname, patientData) => {
      history.push(`/patient/search/${patientData.id}/info/1/care-plans`);
    },
  },
  {
    path: '/patient/search/:patientId/info/:app/referrals/:referralId',
    pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[0-9]+\/referrals\/[0-9]+$/,
    component: useFhirReferrals ? FhirReferralDetail : ReferralDetail,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'REFERRALS', 'REFERRAL DETAIL'],
    icon: Description,
    onClick: (history, currentPathname, patientData) => {},
  },
  // {
  //   path: '/patient/search/:patientId/info/:app/fhir-referrals/:referralId',
  //   pathnameRegex: /^\/patient\/search\/[A-Za-z0-9-_.]+\/info\/[0-9]+\/fhir-referrals\/[0-9]+$/,
  //   component: FhirReferralDetail,
  //   breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'REFERRALS', 'REFERRAL DETAIL'],
  //   icon: Description,
  //   onClick: (history, currentPathname, patientData) => {},
  // },
  {
    path: '/referrals/:referralId',
    pathnameRegex: /^\/referrals\/[0-9]+$/,
    component: useFhirReferrals ? FhirReferralDetail : ReferralDetail,
    breadcrumbs: ['HOME', 'REFERRALS', 'REFERRAL DETAIL'],
    icon: Description,
    onClick: (history, currentPathname, patientData) => {},
  },
  // {
  //   path: '/fhir-referrals/:referralId',
  //   pathnameRegex: /^\/fhir-referrals\/[0-9]+$/,
  //   component: FhirReferralDetail,
  //   breadcrumbs: ['HOME', 'REFERRALS', 'REFERRAL DETAIL'],
  //   icon: Description,
  //   onClick: (history, currentPathname, patientData) => {},
  // },
  {
    path: '/patient/:patientId/file/:docReferenceId/:dataType',
    pathnameRegex: /^\/patient\/[A-Za-z0-9-_.]+\/file\/[0-9]+\/file-?[a-z]*$/,
    component: Viewer,
    breadcrumbs: ['HOME', 'SEARCH', 'PATIENT INFO', 'DOCUMENTS', 'DOC VIEWER'],
    icon: Description,
    onClick: (history, currentPathname, patientData) => {},
  },
  {
    path: '/org',
    pathnameRegex: /^\/org$/,
    component: UserManagement,
    breadcrumbs: ['HOME', 'ORG MANAGEMENT'],
    requiredRoles: ['Manager'],
    icon: Loop,
    onClick: (history, currentPathname, patientData) => {
      history.push('/org');
    },
  },
]);

export const Router = () => {
  useEffect(() => {
    auth.logoutIfRequired();
  })
  return (
    <BrowserRouter>
      <Route exact path="/login" component={Login}/>
      {
        routes.map(route => (
          <PrivateRoute
            exact
            key={route.path}
            path={route.path}
            requiredRoles={route.requiredRoles}
            component={route.component}
          />
        )
        )
      }
      <Route exact path="/confirm-invite/:idInvite" component={ConfirmInvite}/>
    </BrowserRouter>
  );
};

function PrivateRoute ({  component: Component, ...rest }) {
  useEffect(() => {
    auth.logoutIfRequired();
  })
  let currentLocation=useLocation();

  const token = useContext(AuthContext);
  const userHasRequiredRole = rest.requiredRoles?rest.requiredRoles.some(role => token?.userinfo?.application_roles?.includes(role)):true;
  const isAuth = auth.isAuthenticated(token);

  if(isAuth) {
    return(
      <Route
      {...rest}
      render={props =>
        userHasRequiredRole ? <Component {...props} /> :
        null
      }
      />
    )
  } else {
    const match = matchPath(currentLocation.pathname, {
      path: rest.path,
      exact: rest.exact,
    });
    if(match){
      localStorage.setItem(lastUrlKey, window.location.href);
      window.location.href = auth.getLoginUrl();
    }
    return (<div className="hide hidden"/>);
  }
}
