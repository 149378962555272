import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { theme } from "../../../theme";
import { tableIcons } from "../../../table-icons";
import { TaskFilterDialog } from "./TaskFilterDialog";
import { getFilteredItems } from "../../utils/filter-utils";
import { Button, Grid } from "@material-ui/core";
import { TaskEditDialog } from "./TaskEditDialog";
import { CarePlansAPI } from "../../CarePlansAPI";
import { Activity } from "../../utils/fhir-utils";

export function TaskList({ carePlan }) {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  if (!carePlan) {
    return null;
  }

  const activities = carePlan.getActivities();

  const onFilterSelected = (filter) => {
    setFilterDialogOpen(false);
    setFilter(filter);
  };

  const onSubmit = async (activity) => {
    if (activity.isNew()) {
      const newTask = await CarePlansAPI.createCarePlanChild(activity);
      activity.addToCarePlan(newTask);
    } else {
      await CarePlansAPI.updateResource(activity.getRawTask());
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <MaterialTable
          data={getFilteredItems(activities, filter)}
          icons={tableIcons}
          onRowClick={(_, activity) => {
            setSelectedActivity(activity);
            setEditDialogOpen(true);
          }}
          options={{
            emptyRowsWhenPaging: false,
            padding: "dense",
            pageSize: 10,
            search: true,
            sorting: true,
          }}
          columns={[
            {
              title: "Task",
              render: (activity) => {
                return activity?.getDescription();
              },
              customFilterAndSearch: (filter, activity) => {
                return activity
                  ?.getDescription()
                  ?.toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (a1, a2) => {
                return a1.getDescription() <= a2.getDescription() ? -1 : 1;
              },
            },
            {
              title: "Status",
              render: (activity) => {
                return activity?.getStatus();
              },
              customFilterAndSearch: (filter, activity) => {
                return activity
                  ?.getStatus()
                  ?.toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (a1, a2) => {
                return a1.getStatus() <= a2.getStatus() ? -1 : 1;
              },
            },
            {
              title: "Owner",
              render: (activity) => {
                return activity?.getOwnerDisplayName();
              },
              customFilterAndSearch: (filter, activity) => {
                return activity
                  ?.getOwnerDisplayName()
                  ?.toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (a1, a2) => {
                return a1.getOwnerDisplayName() <= a2.getOwnerDisplayName()
                  ? -1
                  : 1;
              },
            },
            {
              title: "Due Date",
              render: (activity) => {
                return activity?.getDueDateMDYFromNow();
              },
              customFilterAndSearch: (filter, activity) => {
                return activity
                  ?.getDueDateMDY()
                  ?.toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (a1, a2) => {
                return a1.getDueDate() <= a2.getDueDate() ? -1 : 1;
              },
            },
          ]}
          title={
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedActivity(new Activity(null, carePlan));
                    setEditDialogOpen(true);
                  }}
                >
                  Add a Task
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFilterDialogOpen(true);
                  }}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          }
        />
      </ThemeProvider>
      <TaskFilterDialog
        activities={activities}
        open={filterDialogOpen}
        onClose={() => {
          setFilterDialogOpen(false);
        }}
        onFilterSelected={onFilterSelected}
      />
      <TaskEditDialog
        activity={selectedActivity}
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        onSubmit={onSubmit}
      />
    </>
  );
}
